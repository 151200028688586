<template>
  <div class="page-container propertydashboard">
    <div class="responsive-container">
      <div class="propertydashboard__header">
        <a class="propertydashboard__back"> </a>
        <div class="propertydashboard__title">
          <h4>imóvel anunciado</h4>
        </div>
        <div>
          <div class="propertydashboard__logo">
            <img src="~@/assets/img/logo-azul.svg" />
          </div>
          <!--<div class="propertydashboard__notifications">
            <a href="#" class="propertydashboard__search"><img src="~@/assets/img/dashboard/icons/search.svg"/></a>
            <a href="#" class="propertydashboard__bell"><img src="~@/assets/img/dashboard/icons/bell.svg"/></a>
            <a href="#" class="propertydashboard__account"><img src="~@/assets/img/dashboard/icons/account.svg"/></a>
          </div>-->
        </div>
      </div>
    </div>
    <div class="responsive-container responsive-container--dashboardbody">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  name: 'PropertyDashboard',
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.responsive-container--dashboardbody {
  @include respond-to(desktop-up) {
    //height: calc(100vh - 100px);
  }
  @include respond-to(big-desktop-up) {
    //height: calc(100vh - 160px);
  }
}

.propertydashboard {
  //width: 100vw;
  //height: 100vh;
  @include respond-to(tablet-portrait-down) {
    background: #fff;

    .responsive-container--dashboardbody {
      padding: 0;
    }
  }
  background: #fff;
  padding-bottom: 50px;

  p,
  a,
  h2,
  h4,
  h3,
  h5 {
    font-family: "Raleway", "Open Sans", sans-serif;
  }

  &__header {
    @include respond-to(tablet-portrait-down) {
      display: none;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;

    & > div {
      display: inline-block;
    }

    .propertydashboard__back {
      cursor: pointer;
      line-height: normal;
      letter-spacing: -0.02em;
      color: #333;
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
    }

    .propertydashboard__title {
      h4 {
        //padding-left: 270px;//gambiarra :) remover assim que possível
        padding-left: 60px;
        text-align: center;
        line-height: normal;
        letter-spacing: -0.04em;
        color: #333;
        font-size: 40px;
        font-weight: normal;
        font-style: normal; //gambiarra :) remover assim que possível
      }
    }

    .propertydashboard__notifications {
      display: flex;
      float: right;
      align-items: center;
      justify-content: center;
      margin-right: 32px;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0 25px;
      height: 80px;
      text-align: center;

      a {
        padding: 0 7px;

        img {
          width: 30px;
        }
      }

      a.propertydashboard__search {
        img {
          padding-right: 7px;
        }
      }
    }

    .propertydashboard__logo {
      display: flex;
      float: right;
      align-items: center;
      justify-content: center;
      height: 80px;
      text-align: right;

      img {
        padding-top: 3.5px;
      }
    }
  }
}
</style>
